<template>
  <div>
    <dv-full-screen-container class="body">
      <div class="toubu">赛训一体数字化教学管理系统</div>

      <div class="boxBody">
        <div class="leftBox">
          <div style="text-align: center; font-size: 20px; margin-top: 13px; color: #00FFF4;">实操成绩排行榜</div>
          <dv-scroll-board :config="config" style="margin-top: 14px;  " />
        </div>

        <div class="centerBox">
          <div class="centerBoxL">
            <div style="color: #00FFF4; margin-top: 73px;">赛事参与学生人数 </div>
            <div style="display: flex; align-items: center; margin-top: 19px;  height: 58px; width: 100%;">
              <div class="NumBox" style="margin-left: 0;">5</div>
              <div class="NumBox">4</div>
              <div class="NumBox">6</div>
            </div>
            <div style="margin-top: 52px;">
              <div style="display: flex; align-items: center;">
                <div style="width: 40px; height: 19px; background-color: #1374FF; border-radius: 4px;"></div>
                <div style="color: #fff; margin-left: 12px;">赛事完成率</div>
              </div>
              <div style="display: flex; align-items: center; margin-top: 18px;">
                <div style="width: 40px; height: 19px; background-color: #29D1FD; border-radius: 4px;"></div>
                <div style="color: #fff; margin-left: 12px;">赛事未完成率</div>
              </div>
            </div>
            <dv-border-box-8 style="height: 119px;margin-top: 66px; ">
              <div
                style="height: 100%;display: flex; flex-direction: column; justify-content: center; align-items: center;">
                <div style="text-align: center; font-size: 34px; color: #66FFFF;">47</div>
                <div style="text-align: center;color: rgba(255,255,255,0.85);">实训项目数量</div>
              </div>
            </dv-border-box-8>
          </div>
          <!-- <div class="centerBoxC" id="centerBoxC"></div> -->
          <chart1 style="flex: 1; height: 100%;"></chart1>
          <div class="centerBoxR">
            <dv-border-box-8 style="height: 119px;margin-top: 33px; ">
              <div
                style="height: 100%;display: flex; flex-direction: column; justify-content: center; align-items: center;">
                <div style="text-align: center; font-size: 34px; color: #66FFFF;">32</div>
                <div style="text-align: center;color: rgba(255,255,255,0.85);">已完成赛事数量</div>
              </div>
            </dv-border-box-8>
            <dv-border-box-8 style="height: 119px;margin-top: 33px; ">
              <div
                style="height: 100%;display: flex; flex-direction: column; justify-content: center; align-items: center;">
                <div style="text-align: center; font-size: 34px; color: #66FFFF;">12</div>
                <div style="text-align: center;color: rgba(255,255,255,0.85);">进行中赛事数量</div>
              </div>
            </dv-border-box-8>
            <dv-border-box-8 style="height: 119px;margin-top: 33px; ">
              <div
                style="height: 100%;display: flex; flex-direction: column; justify-content: center; align-items: center;">
                <div style="text-align: center; font-size: 34px; color: #66FFFF;">8</div>
                <div style="text-align: center;color: rgba(255,255,255,0.85);">即将开始赛事数量</div>
              </div>
            </dv-border-box-8>
          </div>

        </div>

        <div class="rightBox">
          <div style="text-align: center; font-size: 20px; margin-top: 13px; color: #00FFF4;">理论成绩排行</div>
          <dv-scroll-board :config="config" style="margin-top: 14px; " />
        </div>

      </div>
      <div class="boxBottom">
        <dv-border-box-8 style="width: 50%;">
          <div style="display: flex; height: 100%; padding: 19px 30px;">
            <div class="data">
              <div class="color00FFF4">实操平均分数</div>
              <dv-border-box-1 style="width: 155px; height: 158px; margin-top: 20px;">
                <div
                  style="height: 100%;display: flex; flex-direction: column; justify-content: center; align-items: center;">
                  <div style="text-align: center; font-size: 34px; color: #66FFFF;">82</div>
                  <div style="text-align: center;color: rgba(255,255,255,0.85);">本月实操平均分数</div>
                </div>
              </dv-border-box-1>
            </div>
            <chart2 style="flex: 1; height: 100%;"></chart2>
          </div>
        </dv-border-box-8>

        <dv-border-box-8 style="width: 50%;">
          <div style="display: flex; height: 100%; padding: 19px 30px;">
            <div class="data">
              <div class="color00FFF4">理论平均分数</div>
              <dv-border-box-1 style="width: 155px; height: 158px; margin-top: 20px;">
                <div
                  style="height: 100%;display: flex; flex-direction: column; justify-content: center; align-items: center;">
                  <div style="text-align: center; font-size: 34px; color: #66FFFF;">82</div>
                  <div style="text-align: center;color: rgba(255,255,255,0.85);">本月理论平均分数</div>
                </div>
              </dv-border-box-1>
            </div>
            <chart3 style="flex: 1; height: 100%;"></chart3>
          </div>
        </dv-border-box-8>
      </div>

    </dv-full-screen-container>
  </div>
</template>
<script>
import chart1 from "../components/yam2/chart1.vue";
import chart2 from "../components/yam2/chart2.vue";
import chart3 from "../components/yam2/chart3.vue";
export default {
  components: {
    chart1,
    chart2,
    chart3,
  },
  data () {
    return {
      config: {
        header: ["头像", "姓名", "名次", "成绩"],
        data: [
          [`<div  style="margin: 0 auto; border-radius: 50%;  width: 32px; height: 32px; background:url('${require('..//assets/tb/profile.jpg')}')  no-repeat; background-size: cover ;"></div>`, "淦傲菡", "1", "120"],
          [`<div  style="margin: 0 auto; border-radius: 50%; width: 32px; height: 32px; background:url('${require('@/assets/tb/1.jpg')}')  no-repeat; background-size: cover ;"></div>`, "力同和", "2", "119"],
          [`<div  style="margin: 0 auto; border-radius: 50%;  width: 32px; height: 32px; background:url('${require('@/assets/tb/2.jpg')}')  no-repeat; background-size: cover ;;"></div>`, "力同和", "3", "118"],
          [`<div  style="margin: 0 auto; border-radius: 50%;  width: 32px; height: 32px; background:url('${require('@/assets/tb/3.jpg')}')  no-repeat; background-size: cover ;;"></div>`, "郸琨瑶", "4", "109"],
          [`<div  style="margin: 0 auto; border-radius: 50%;  width: 32px; height: 32px; background:url('${require('@/assets/tb/4.jpg')}')  no-repeat; background-size: cover ;;"></div>`, "淦傲菡", "5", "105"],
          [`<div  style="margin: 0 auto; border-radius: 50%;  width: 32px; height: 32px; background:url('${require('@/assets/tb/5.jpg')}')  no-repeat; background-size: cover ;;"></div>`, "肖力言", "6", "102"],
          [`<div  style="margin: 0 auto; border-radius: 50%;  width: 32px; height: 32px; background:url('${require('@/assets/tb/6.jpg')}')  no-repeat; background-size: cover ;;"></div>`, "力同和", "7", "100"],
          [`<div  style="margin: 0 auto; border-radius: 50%;  width: 32px; height: 32px; background:url('${require('@/assets/tb/7.jpg')}')  no-repeat; background-size: cover ;;"></div>`, "郸琨瑶", "8", "97"],
          [`<div  style="margin: 0 auto; border-radius: 50%;  width: 32px; height: 32px; background:url('${require('@/assets/tb/8.jpg')}')  no-repeat; background-size: cover ;;"></div>`, "力同和", "9", "91"],
          [`<div  style="margin: 0 auto; border-radius: 50%;  width: 32px; height: 32px; background:url('${require('@/assets/tb/9.jpg')}')  no-repeat; background-size: cover ;"></div>`, "淦傲菡", "10", "88"],
        ],
        waitTime: 2000,
        rowNum: 9
      },
    }
  },
  mounted () {
  },
  methods: {
  },
}
</script>
<style scoped>
.color00FFF4 {
  font-size: 20px;
  color: #00FFF4;
}

.body {
  /* padding-bottom: 100px; */
  min-height: 100vh;
  width: 100%;
  background: url('../assets/nybj3.png') no-repeat;
  background-size: cover;
  background-color: #01004C;
}

.toubu {

  height: 86px;
  width: 100%;
  text-align: center;
  font-size: 36px;
  padding-top: 22px;
  color: #fff;
  background: url('../assets/nybj2.png') no-repeat;
  background-size: cover;
}

.boxBody {
  display: flex;
  align-items: center;
  padding: 16px 28px;
}

.leftBox {
  width: 437px;
  height: 518px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(2, 0, 77, 0.4);
  border: 1px solid #0D3770;
}

.centerBox {
  flex: 1;
  display: flex;
  align-items: center;
  margin: 0 18px;
  border: 1px solid #0D3770;
  height: 518px;
  padding: 0px 35px;
}

.centerBox .centerBoxL {
  width: 30%;
  height: 100%;
}

.centerBox .centerBoxC {
  flex: 1;
  height: 100%;
}

.centerBox .centerBoxR {
  width: 30%;
  height: 100%;
}

.NumBox {
  width: 65px;
  height: 58px;
  margin-left: 10px;
  font-weight: 400;
  font-size: 42px;
  color: #00FFF4;
  text-align: center;
  line-height: 55px;
  background: url('../assets/numBox.png') no-repeat;
}

.centerBox .centerBoxR {
  width: 30%;
  height: 100%;
}

.rightBox {
  width: 437px;
  height: 518px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(2, 0, 77, 0.4);
  border: 1px solid #0D3770;
}

::v-deep .header {
  background: linear-gradient(180deg, #17B0FE 0%, #3F5EFD 100%);
}

::v-deep .header .header-item {
  text-align: center !important;
}

::v-deep .rows .row-item {
  height: 50px !important;
  line-height: 50px !important;
}

::v-deep .rows .row-item:nth-child(odd) {
  background-color: #0D3770 !important;
}

::v-deep .rows .row-item:nth-child(even) {
  background-color: transparent !important;
}

::v-deep .rows .row-item .ceil {
  text-align: center !important;
  line-height: 40px !important;
  padding: 8px 0px;
}

.boxBottom {
  display: flex;
  align-items: center;
  height: 406px;
  padding: 0px 28px;
}

.data {
  width: 158px;
}
</style>
